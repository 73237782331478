<template>
    <div class="page-table haberTanim mainDiv" id="affix-container">
        <div class="animated fadeInUp card-base baslikBg">
            {{$t("src.views.apps.haberler.guncelleme.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button v-on:click="refreshPage()" size="mini" type="text" icon="el-icon-refresh"></el-button>
                </el-tooltip>
            </div>
        </div>
        
        <!-- Loading true oldugunda loading textti yazılacak eğer false ise o zaman detailLoading true değeri almıştır detailLoading mesajı gösterilecek -->
        <el-row :gutter="10" v-loading="loading || detailLoading"
        :element-loading-text='loading ? $t("src.views.apps.haberler.guncelleme.updateLoading") : $t("src.views.apps.haberler.guncelleme.detailLoading")'
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(255, 255, 255, 1)">
            <el-form status-icon :model="haberlerForm" :rules="rulesHaberler" ref="haberlerForm">
                <el-col :lg="18" :md="18" :sm="24" :xs="24">
                    <div class="card-base card-shadow--medium demo-box bg-white animated fadeInLeft">
                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.baslik")' prop="baslik">:
                                    <el-input size="small" v-model="haberlerForm.baslik" :placeholder='$t("src.views.apps.messages.enterBultenTitle")'></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.altbaslik")' prop="altBaslik">:
                                    <el-input size="small" v-model="haberlerForm.altBaslik" :placeholder='$t("src.views.apps.messages.enterSubTitle")'></el-input>
                                </el-form-item>
                            </el-col>

                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.kategoriler")' prop="kategori">:
                                    <el-select v-model="haberlerForm.kategori" style="width: 100%" size="mini">
                                        <div v-loading="catLoading">
                                            <el-option v-for="item in kategoriListe" :key="item.bkategoriID" :label="item.baslik" :value="item.bkategoriID"></el-option>
                                        </div>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                <el-form-item :label='$t("src.views.apps.haberler.tanimlama.icerik")' prop="icerik">:
                                    <div>
                                        <ckeditor :editor="editor" :config="editorConfig" v-model="haberlerForm.icerik"></ckeditor>
                                    </div>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col :lg="12" :md="12" :sm="24" :xs="24" class="col-p">
                                <el-form-item>
                                    <el-checkbox v-model="videoVis">{{$t("src.views.apps.haberler.guncelleme.videoOlsunMu")}}</el-checkbox>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </div>

                    <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                        <el-row :gutter="10">
                            <el-col v-if="videoVis" style="float: right" :lg="12" :md="12" :sm="24" :xs="24">
                                <el-collapse-item class=" animated fadeInDown card-shadow--medium" title='Video' name="2">
                                    <el-row>
                                        <el-col :lg="24" :md="24" :sm="24" :xs="24" class="col-p">
                                            <el-form-item :label='$t("src.views.apps.haberler.guncelleme.video")' prop='video'>:
                                                <el-input size="small"
                                                    type="textarea"
                                                    :rows="5"
                                                    v-model="haberlerForm.video"
                                                    :placeholder='$t("src.views.apps.messages.enterVideoURL")'></el-input>
                                            </el-form-item>
                                        </el-col>
                                    </el-row>
                                </el-collapse-item>
                            </el-col>
                        </el-row>
                    </el-collapse>

                    <el-collapse class="animated fadeInUp" style="margin-top: 10px" v-model="activeCollapse">
                        <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.genel.resimAyarlari")' name="4">
                            <Resim functionName="haberGuncelleImage" :imageList="imageList" :link="haberlerForm.link" :selectedsFromServer="selectedImagesFromServer"></Resim>
                        </el-collapse-item>

                        <!-- Belge componenti belge yüklemek için var. Lazım oldugunda buradan kopyalanabilir. Örnek olsun diye silmedim lazım oldugunda burdan kullanın!. Güncelleme işlemlerinde propları bu sekilde olmalı. Tanımlama için örneğini sayfaTanimla.vue de bulabilirsiniz. Ordan kopyalayabilirsiniz! -->

                        <!-- <el-collapse-item class="card-shadow--medium" title="Belge Ayarları" name="5">
                            <Belge functionName="haberGuncelleBelge" :belgeList="belgeList" :link="haberlerForm.link" :selectedsFromServer="selectedFilesFromServer"></Belge>
                        </el-collapse-item> -->
                    </el-collapse>

                    <el-collapse style="margin-top: 10px" class="animated fadeInUp" v-model="activeCollapse">
                        <draggable data-source="juju" class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.genel.seoAyarlari")' name="3">
                                <seo :modulName="'haber'" :baslik="haberlerForm.baslik" :icerik="haberlerForm.icerik" v-on:changeSeo="handleSeo" :seoBaslikF="haberlerForm.seoBaslik" :seoIcerikF="haberlerForm.seoIcerik"></seo>
                            </el-collapse-item>
                        </draggable>
                    </el-collapse>
                </el-col>

                <el-col :lg="6" :md="6" :sm="24" :xs="24">
                    <el-collapse class="yanForm animated fadeInUp delay-1s" v-model="activeCollapse">
                        <el-collapse-item name="6" :title='$t("src.views.apps.genel.durum")'>
                            <el-row>
                                <el-button
                                    style="margin-bottom: 10px"
                                    size="mini" v-on:click="haberGuncelle('haberlerForm')"
                                    class="onayBtn"
                                    type="primary">
                                    {{$t('src.views.apps.genel.guncelle')}}
                                </el-button>
                            </el-row>
                        </el-collapse-item>

                        <draggable class="list-group" group="a">
                            <el-collapse-item class="card-shadow--medium" :title='$t("src.views.apps.haberler.tanimlama.tarih")' name="9">
                                <el-row :gutter="5">
                                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                                        <el-row>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-form-item style="position: relative;top: -25px" :label='$t("src.views.apps.haberler.tanimlama.baslamaTarihi")' prop="tarih">
                                                    <el-date-picker size="small" class="select-wide" type="datetime"
                                                        :placeholder='$t("src.views.apps.message.selectDate")'
                                                        :picker-options="dateRangeOptions"
                                                        v-model="haberlerForm.tarih"
                                                        format="yyyy-MM-dd HH:mm:ss"
                                                        value-format="yyyy-MM-dd HH:mm:ss">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :lg="12" :md="12" :sm="12" :xs="12" class="col-p">
                                                <el-checkbox v-if="!checkedTarih" style="margin-bottom: 15px" v-model="checkedTarih">
                                                    {{$t("src.views.apps.haberler.tanimlama.bitisTarihi")}}
                                                </el-checkbox>
                                                <el-col v-if="checkedTarih" :lg="24" :md="24" :sm="24" :xs="24"
                                                        class="col-p">
                                                    <el-form-item style="position: relative;top: -20px" :label='$t("src.views.apps.haberler.tanimlama.bitisTarihi")' prop="bitisTarihi">:
                                                        <el-date-picker size="small" class="select-wide" type="datetime"
                                                            :placeholder='$t("src.views.apps.message.selectDate")'
                                                            :picker-options="dateRangeOptions"
                                                            v-model="haberlerForm.bitisTarihi"
                                                            format="yyyy-MM-dd HH:mm:ss"
                                                            value-format="yyyy-MM-dd HH:mm:ss">
                                                        </el-date-picker>
                                                    </el-form-item>
                                                </el-col>
                                            </el-col>
                                        </el-row>
                                    </el-col>
                                </el-row>
                            </el-collapse-item>

                            <!-- <konumlar :modulName="'haber'" :resim="haberlerForm.resimKonum" :menu="haberlerForm.menuKonum" :galeri="haberlerForm.galeriKonum" v-on:changeKonum="handleKonum"></konumlar> -->
                        </draggable>
                    </el-collapse>
                </el-col>
            </el-form>
        </el-row>
    </div>
</template>

<script>
    import haberService from '../../../WSProvider/HaberService'
    import haberKategoriService from '../../../WSProvider/HaberKategoriService'
    import notification from '../../../notification'
    import functions from '../../../functions'

    import ClassicEditor from '../../../../node_modules/ckeditor5-build-classic';
    import draggable from "vuedraggable";
    import EventBus from '@/components/event-bus'
    import Resim from '@/components/Resim.vue'
    import Belge from '@/components/Belge.vue'
    import Seo from '@/components/Seo.vue'
    import Konumlar from '@/components/Konumlar.vue'

    var moment = require('moment');

    export default {
        name: "HaberlerDuzenle",
        components: {
            draggable,
            Resim,
            Belge,
            Seo,
            Konumlar
        },
        computed: {
            // imageListMain(){
            //     return this.haberlerForm.resim
            // },

            haberStoreData(){
                return this.$store.getters.getHaberData;
            }
        },
        watch: {
            // imagelistmaini takip etmelerinin sebebi resim konum vs. değiştiginde otomatik bir sayfa duzenine atanması. Ama suanki durumda zaten sayfa düzeni tek bir durumda oldugu için konumlar kaldırıldı.İhtiyac haline yorum satırı kaldırılmalı!
            // imageListMain: function (val) {
            //     if (val.length === 1) {
            //         this.haberlerForm.resimKonum = "1";
            //         this.haberlerForm.galeriKonum = "0";
            //     } else if (val.length > 1) {
            //         this.haberlerForm.galeriKonum = "1";
            //     } else {
            //         this.haberlerForm.resimKonum = "0";
            //         this.haberlerForm.galeriKonum = "0";
            //     }
            // },
            checkedTarih: function (val) {
                if (val === false) {
                    this.haberlerForm.bitisTarihi = "";
                }
            },
            haberStoreData(val){
                this.updateHaberData = this.$store.getters.getHaberData
                this.getHaberDetay(this.updateHaberData.haberID, this.updateHaberData.link)
            }
        },
        mounted() {
            const self = this;
            this.updateHaberData = this.$store.getters.getHaberData
            this.getHaberDetay(this.updateHaberData.blogID, this.updateHaberData.link)
            this.getKategoriCount()

            EventBus.$on("haberGuncelleImage", function(bool, imageArray) {
                if (bool) {
                    self.haberlerForm.resim = imageArray;
                }
            });

            EventBus.$on("haberGuncelleBelge", function(bool, belgeArray) {
                if (bool) {
                    self.haberlerForm.belge = belgeArray;
                }
            });
        },
        data() {
            return {
                totalKategori: 0,
                loading: false,
                detailLoading : false,

                imageList:[],
                belgeList: [],
                selectedImagesFromServer: [],
                selectedFilesFromServer: [],
                path: haberService.path,
                imagePath: haberService.imagePath,
                belgePath: haberService.belgePath,

                videoVis: false,

                activeCollapse: ['1', '2', '3', '4', '5', '6', '7', '8','9', '10'],
                editor: ClassicEditor,
                editorConfig: {
                    language: 'tr'
                },
                checkedTarih: '',
                kategoriListe: [],
                updateHaberData: '',
                haberlerForm: {
                    kategori:"",
                    haberID: "",
                    baslik: '',
                    link: '',
                    video: "",
                    altBaslik: '',
                    icerik: '',
                    resim: [],
                    belge: [],
                    // -------------- normal sartlarda ana sitede sayfanın düzeni kontrol edilebilir oluyormus. Yanı eklenen resimlerin konumu yukarıda mı asagıda mı olsun gibi. Ama suan da ona uygun bir yapı olmadıgı için component yukarıda yorum satırı olarak kaldı. Lazım oldugunda yorum satırı kaldırılıp kullanılır.
                    resimKonum: '',
                    menuKonum: '',
                    galeriKonum: '',
                    // ---------------
                    baslamaTarihi: '',
                    bitisTarihi: '',
                    durum: '',
                    seoBaslik: '',
                    seoIcerik: '',
                },
                dateRangeOptions: {
                    firstDayOfWeek: 1
                },
                rulesHaberler: {
                    baslik: [{
                        required: true,
                        message: "Lütfen Haber Başlığı Giriniz",
                        trigger: 'blur'
                    }],
                    kategori: [{
                        required: true,
                        message: "Lütfen Kategori Seçiniz",
                        trigger: ['blur', 'trigger']
                    }],
                    seoBaslik: [{
                        max: 70,
                        message: "Tavsiye edilen SEO Başlık limitini aştınız.",
                        trigger: 'change'
                    }],
                    seoIcerik: [{
                        max: 160,
                        message: "Tavsiye edilen SEO İçerik limitini aştınız.",
                        trigger: 'change'
                    }],
                },
            }
        },
        methods: {
            refreshPage(){
                this.loading = false;
                this.detailLoading = false;
                this.getHaberDetay(this.updateHaberData.haberID, this.updateHaberData.link)
            },

            handleSeo(seoBaslik,seoIcerik) {
                this.haberlerForm.seoBaslik = seoBaslik;
                this.haberlerForm.seoIcerik = seoIcerik;
            },

            handleKonum(resimKonum,menuKonum,galeriKonum) {
                this.haberlerForm.resimKonum = resimKonum;
                this.haberlerForm.menuKonum = menuKonum;
                this.haberlerForm.galeriKonum = galeriKonum;
            },

            getKategoriListe() {
                this.catLoading = true;
                haberKategoriService.hKategoriListe(1, 0 , this.totalKategori).then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataBGSurec", response.token)
                        this.kategoriListe = response.data;
                        this.haberlerForm.kategori = response.data.length > 0 ? response.data[0].bkategoriID : '0'
                    }
                    this.catLoading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){  
                            this.kategoriListe = [];
                            this.haberlerForm.kategori = "";
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.catLoading = false;
                })
            },

            getKategoriCount() {
                haberKategoriService.hKategoriSayisi(1).then(response => {
                    if(response.status == 200){
                        localStorage.setItem("userDataBGSurec", response.token);
                        this.totalKategori = response.data;
                        this.getKategoriListe();
                    }
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }else if(error.status == 404){  
                            this.totalKategori = 0
                        }
                    }else{
                        //notification.Status(503, this)
                    }
                })
            },

            getHaberDetay(haberID, link) {
                let self = this;
                this.detailLoading = true;
                haberService.haberDetay(haberID,link).then((response) => {
                    if (response.status == 200) {
                        localStorage.setItem("userDataBGSurec", response.token);
                        response.resimler.forEach(element => {
                            if(element.resim !== ""){
                                var item = {
                                    tmp_name: element.resim,
                                    name: element.resim,
                                    url: this.imagePath + element.resim,
                                }
                                this.imageList.push(item);
                            }
                        })
                        
                        self.haberlerForm.haberID = response.data.haberID
                        self.haberlerForm.kategori = response.data.kID;
                        self.haberlerForm.baslik = response.data.baslik;
                        self.haberlerForm.link = response.data.link;
                        self.haberlerForm.altBaslik = response.data.altBaslik;
                        self.haberlerForm.icerik = response.data.icerik ? response.data.icerik : "";
                        
                        if (response.data.video) {
                            this.videoVis = true;
                            this.haberlerForm.video = (response.data.video);
                        }
                        self.haberlerForm.baslamaTarihi = moment(response.data.haberBaslamaTarih).format('DD-MM-YYYY HH:mm:ss');

                        if(response.data.haberBitisTarih){
                            this.checkedTarih = true
                            self.haberlerForm.bitisTarihi = moment(response.data.haberBitisTarih).format('DD-MM-YYYY HH:mm:ss');
                        }

                        self.haberlerForm.durum = response.data.durum;
                        self.haberlerForm.seoBaslik = response.data.seo_baslik;
                        self.haberlerForm.seoIcerik = functions.htmlDecode(functions.strip_html_tags(response.data.seo_icerik));
                        self.haberlerForm.resimKonum = response.data.resimKonum
                        self.haberlerForm.menuKonum = response.data.menuKonum
                        self.haberlerForm.galeriKonum = response.data.galeriKonum
                    }
                    this.haberlerForm = functions.removeSlashesAndDecodeStrings(this.haberlerForm)
                    this.detailLoading = false;
                }).catch(err => {
                    if(err.responseJSON){
                        let error = err.responseJSON
                        if(error.status == 401){
                            notification.Status(150, this);
                        }
                        else notification.Status("errorMsg", this, error.errMsg)
                    }else{
                        //notification.Status(503, this)
                    }
                    this.detailLoading = false;
                })
        
            },
            
            haberGuncelle(formName) {
                this.loading = true;
                this.$refs[formName].validate((valid, errors) => {
                    if(valid){
                        this.$confirm("Haberi güncellemek istediğinize emin misiniz?", this.$t("src.views.apps.genel.uyari"), {
                            confirmButtonText: this.$t("src.views.apps.genel.yes"),
                            cancelButtonText: this.$t("src.views.apps.genel.no"),
                            type: 'warning'
                        }).then(() => {
                            var resimUzunluk = this.haberlerForm.resim.length;
                            var belgeUzunluk = this.haberlerForm.belge.length; // belge ile ilgili bi işlem yok ama lazım oldugunda kullanılır
                            var sunucudanEklenenResimler = this.selectedImagesFromServer.join(",");

                            //haberID, baslik, altBaslik, icerik, sunucudanEklenenResimler, resim, resimUzunluk, video, resimKonum, menuKonum, galeriKonum, baslamaTarihi, bitisTarihi, durum
                            haberService.haberGuncelleme(this.haberlerForm.haberID, this.haberlerForm.kategori, this.haberlerForm.baslik, this.haberlerForm.altBaslik, this.haberlerForm.icerik, sunucudanEklenenResimler, this.haberlerForm.resim.length > 0 ? this.haberlerForm.resim : "", resimUzunluk, this.haberlerForm.video, this.haberlerForm.resimKonum, this.haberlerForm.menuKonum, this.haberlerForm.galeriKonum, this.haberlerForm.baslamaTarihi, this.haberlerForm.bitisTarihi, this.haberlerForm.durum, this.haberlerForm.seoBaslik, this.haberlerForm.seoIcerik).then((response) => {
                                if(response.status == 200){
                                    EventBus.$emit("haberList", true)
                                    this.resetForm("haberlerForm")
                                    functions.sayfaKapat("blogduzenle", this);
                                }
                                localStorage.setItem("userDataBGSurec", response.token)
                                notification.Status("success", this, response.msg);
                                this.loading = false
                            }).catch(err => {
                                if(err.responseJSON){
                                    let error = err.responseJSON
                                    if(error.status == 401){
                                        notification.Status(150, this);
                                    }
                                    else notification.Status("errorMsg", this, error.errMsg)
                                }else{
                                    //notification.Status(503, this)
                                }
                                this.loading = false;
                            })
                        }).catch(e => {})
                    }else{
                        for(var k in errors){
                            errors[k].forEach((element) => {
                                notification.Status( "warning", this, element.message);
                            })
                            this.updateLoading = false;
                        }
                    }
                })    
            },

            resetForm(formName) {
                this.$refs[formName].resetFields();
                this.visibleSil = false;
                this.haberlerForm.link = null;

                functions.sayfaKapat('bultenduzenle')
            },

        }
    }
</script>